import React, { useState, useEffect, createRef, useRef } from "react"
import useOverview from "../hooks/use-overview"
import get from "lodash/get"
import BarChart from "./BarChart"
import theme from "../styles/theme"
import styled from "styled-components"
import TrackVisibility from "react-on-screen"
import result from "lodash/result"
import {
  VictoryChart,
  VictoryBar,
  VictoryGroup,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
} from "victory"
import useChartData from "../hooks/use-chart-data"

const GraphTooltip = props => {
  const { datum, x, y, highlightColor } = props
  const [offset, updateOffset] = useState(null)
  const elementRef = createRef()
  useEffect(() => {
    const windowWidth =
      typeof window !== "undefined" ? get(window, "innerWidth") : null
    const bounds = result(elementRef.current, "getBoundingClientRect")
    if (bounds && offset === null) {
      if (bounds.x < 0) {
        updateOffset(Math.abs(bounds.x))
        return
      }
      if (windowWidth) {
        if (bounds.x + bounds.width > windowWidth) {
          updateOffset(windowWidth - (bounds.x + bounds.width + 16))
          return
        }
      }
      updateOffset(0)
    }
  }, [elementRef, offset])

  const currentOffset = offset === null ? 0 : offset
  return (
    <g style={{ pointerEvents: "none", opacity: offset === null ? 0 : 1 }}>
      <foreignObject
        x={x - 150 + currentOffset}
        y={y - 110}
        width="300"
        height="115"
        ref={elementRef}
      >
        <GraphTooltipWrapper
          className="graph-tooltip"
          offset={currentOffset}
          highlightColor={highlightColor}
        >
          <div className="graph-tooltip__inner">
            <div className="title">{`${parseInt(datum.y * 100) / 100}%`}</div>
            <div className="text">{datum.x}</div>
          </div>
        </GraphTooltipWrapper>
      </foreignObject>
    </g>
  )
}

const GraphTooltipWrapper = styled.div`
  &,
  .graph-tooltip {
    position: relative;
    padding: 5px;
    text-align: left;

    &__inner {
      width: 100%;
      height: calc(100% - 20px);
      padding: 1.25rem;
      border-radius: 12px;
      box-shadow: 0 2px 5px 0 rgba(98, 101, 110, 0.3);
      background: white;
    }

    .title {
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.34px;
      color: ${({ theme, highlightColor }) =>
        theme[highlightColor] || theme.grey1} !important;
      margin-bottom: 10px;
    }

    .text {
      font-family: ${({ theme }) => theme.fonts["vollkorn"]};
      font-size: 0.875rem;
      line-height: 1.43;
      letter-spacing: -0.09px;
      color: ${({ theme }) => theme.grey1} !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      bottom: -15px;
      left: calc(50% - 10px);
      border: 10px solid transparent;
      border-top: 12px solid white;
      transform: translate(${({ offset }) => -offset}px, 0);
    }
  }
`

const LPChart = ({
  width = 700,
  height = 440,
  mode = "light",
  selectedItems = [],
  barWidth = 8,
  highlightColor = "grey1",
  padding = { left: 30, right: 20, top: 5, bottom: 5 },
  domainPadding = { x: 25, y: 35 },
}) => {
  const categories = ["Stakeholder engagement", "Open data"]

  const colorScale = ["#FFF", "rgb(154, 145, 177)", "rgb(124, 115, 147)"]

  const overviewData = useChartData()
  const data = [
    [
      {
        x: categories[0],
        y: overviewData.stakeholders.stakeholder[2],
        label: "",
      },
      { x: categories[1], y: overviewData.stakeholders.openData[2], label: "" },
    ],
    [
      {
        x: categories[0],
        y: overviewData.stakeholders.stakeholder[1],
        label: "",
      },
      { x: categories[1], y: overviewData.stakeholders.openData[1], label: "" },
    ],
    [
      {
        x: categories[0],
        y: overviewData.stakeholders.stakeholder[0],
        label: "",
      },
      { x: categories[1], y: overviewData.stakeholders.openData[0], label: "" },
    ],
  ]
  const maxDomains = []
  let i = 0
  for (let category of categories) {
    maxDomains[i] = Math.max(data[0][i].y, data[1][i].y)
    i++
  }
  const maxDomain = Math.max(...maxDomains)

  const legendRef = useRef(null)

  return (
    <TrackVisibility once partialVisibility>
      {({ isVisible }) =>
        isVisible && (
          <>
            <Legend ref={legendRef}>
              <div>Highest level practice</div>
              <div>Medium level practice</div>
              <div>Lowest level practice</div>
            </Legend>
            <div className="chart-wrapper" style={{ height: height }}>
              <VictoryChart
                categories={{ x: categories }}
                animate={{ duration: 500, easing: "polyInOut" }}
                width={width}
                height={height}
                // padding={padding}
                maxDomain={{ x: categories.length + 1, y: maxDomain }}
                minDomain={{ x: 0, y: 60 }}
                domainPadding={domainPadding}
                style={{
                  parent: {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    touchAction: "auto",
                  },
                }}
              >
                <VictoryGroup colorScale={colorScale} offset={width / 28}>
                  {data.map((step, i) => (
                    <VictoryBar
                      key={i}
                      data={step}
                      barWidth={width / 36}
                      categories={{ x: categories }}
                      labelComponent={
                        <VictoryTooltip
                          flyoutComponent={
                            <GraphTooltip
                              selectedItems={selectedItems}
                              highlightColor={highlightColor}
                            />
                          }
                        />
                      }
                    ></VictoryBar>
                  ))}
                </VictoryGroup>
                <VictoryAxis
                  dependentAxis
                  style={styles[mode].yAxis}
                  tickFormat={el => {
                    return el + " %"
                  }}
                />
                <VictoryAxis
                  tickFormat={categories}
                  style={styles[mode].xAxis}
                  tickLabelComponent={
                    <VictoryLabel
                      y={355}
                      text={datum => {
                        return categories[datum.index].split(" ")
                      }}
                    />
                  }
                />
              </VictoryChart>
            </div>
          </>
        )
      }
    </TrackVisibility>
  )
}

const Legend = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  font-family: ${({ theme }) => theme.fonts["roboto"]};
  font-size: 0.85rem;
  font-weight: 600;
  div {
    padding-left: 24px;
    margin-bottom: 12px;
    position: relative;
    font-size: 1em;
    text-align: left;
  }

  div::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  div:nth-child(1)::before {
    background-color: rgb(255, 255, 255);
  }

  div:nth-child(2)::before {
    background-color: rgb(154, 145, 177);
  }
  div:nth-child(3)::before {
    background-color: rgb(124, 115, 147);
  }

  /* const colorScale = ["#FFF", "rgb(203, 214, 226)", "rgb(128, 156, 183)"] */
`

const styles = {
  light: {
    yAxis: {
      axisLabel: {
        fontFamily: "Roboto Condensed",
        fill: "white",
        padding: 40,
        fontSize: 8,
      },
      axis: {
        stroke: "white",
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1,
      },
      tickLabels: {
        fill: "white",
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
    xAxis: {
      axis: {
        stroke: "white",
      },
      grid: {
        stroke: "transparent",
      },
      tickLabels: {
        fill: "white",
        fontSize: 9,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
  },
  dark: {
    yAxis: {
      axis: {
        stroke: "#dddee0",
      },
      grid: {
        stroke: "#dddee0",
        opacity: "0.55",
        strokeWidth: 1,
      },
      tickLabels: {
        fill: theme.grey1,
        fontSize: 12,
        fontFamily: "Roboto Condensed",
        fontWeight: "bold",
      },
    },
    xAxis: {
      axis: {
        stroke: "#dddee0",
      },
      grid: {
        stroke: "transparent",
      },
    },
  },
}

export default LPChart
