import React, { useEffect } from "react"
import { graphql } from "gatsby"
import debounce from "lodash/debounce"
import orderBy from "lodash/orderBy"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import get from "lodash/get"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import PageIntro from "../components/PageIntro"
import LocationPin from "../components/styled-components/LocationPin"
import PoliciesBarChart from "../components/PoliciesBarChart"
import OutcomesChart from "../components/OutcomesChart"
import StaffChart from "../components/StaffChart"
import PSIChart from "../components/PSIChart"
import LevelPracticeChart from "../components/LevelPracticeChart"
import ResponsiveChart from "../components/ResponsiveChart"

const SHOW_ABOUT_SECTION = false

export const OutcomesPageTemplate = ({
  children,
  page_intro = {},
  outcomes_overview = {},
  staff_overview = {},
  psi_overview = {},
  level_practice_overview = {},
  fluidImage,
  fluidImageB,
  isPreview = false,
}) => {
  return (
    <HomeWrapper isPreview={false}>
      <section className="page-header">
        {fluidImageB && (
          <div className="page-header__background">
            <ImageBackground
              Tag="div"
              fluid={fluidImageB}
              style={{
                backgroundPosition: "bottom left",
                backgroundSize: "100% auto",
              }}
            />
          </div>
        )}
        <div className="container">
          <div className="inner-container">
            <div className="columns">
              <div className="column column-6">
                <PageIntro {...page_intro} />
              </div>
              <div
                className="column column-6"
                style={{ marginBottom: 0, position: "relative" }}
              >
                {fluidImage && (
                  <ImageBackground
                    Tag="div"
                    fluid={fluidImage}
                    style={{
                      backgroundPosition: "top left",
                      backgroundSize: "contain",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="overviews">
        <section className="overview overview--outcomes">
          <div className="overview__background" />
          <div className="overview__inner">
            <div className="overview__flag left" />
            <div className="container">
              <div className="">
                <div className="columns">
                  <div className="column column-8">
                    <div className="overview-block">
                      <div className="overview-block__chart">
                        {isPreview ? (
                          <div className="chart-placeholder" />
                        ) : (
                          <ResponsiveChart
                            initialWidth={700}
                            initialHeight={420}
                            delay={250}
                            mobileAspectRatio={0.78}
                          >
                            {({ width, height }) => (
                              <OutcomesChart width={width} height={height} />
                            )}
                          </ResponsiveChart>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="column column-4">
                    <div className="overview__text">
                      <div className="overview__pin">
                        <LocationPin className="dark-blue" />
                      </div>
                      <ReactMarkdown source={outcomes_overview.title} />
                      <div className="large">
                        <ReactMarkdown source={outcomes_overview.subtitle} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="overview overview--staff">
          <div className="overview__background" />
          <div className="overview__inner">
            <div className="overview__flag right" />
            <div className="container">
              <div className="">
                <div className="columns">
                  <div className="column column-4">
                    <div className="overview__text">
                      <div className="overview__pin">
                        <LocationPin className="light-green" />
                      </div>
                      <ReactMarkdown source={staff_overview.title} />
                      <div className="large">
                        <ReactMarkdown source={staff_overview.subtitle} />
                      </div>
                    </div>
                  </div>
                  <div className="column column-8">
                    <div className="overview-block">
                      <div className="overview-block__chart">
                        {isPreview ? (
                          <div className="chart-placeholder" />
                        ) : (
                          <ResponsiveChart
                            initialWidth={700}
                            initialHeight={420}
                            delay={250}
                            mobileAspectRatio={0.78}
                          >
                            {({ width, height }) => (
                              <StaffChart
                                width={width}
                                height={height}
                                barWidth={17}
                              />
                            )}
                          </ResponsiveChart>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="overview overview--psi">
          <div className="overview__background" />
          <div className="overview__inner">
            <div className="overview__flag left" />
            <div className="container">
              <div className="">
                <div className="columns">
                  <div className="column column-8">
                    <div className="overview-block">
                      <div className="overview-block__chart">
                        {isPreview ? (
                          <div className="chart-placeholder" />
                        ) : (
                          <ResponsiveChart
                            initialWidth={700}
                            initialHeight={420}
                            delay={250}
                            mobileAspectRatio={0.78}
                          >
                            {({ width, height }) => (
                              <PSIChart width={width} height={height} />
                            )}
                          </ResponsiveChart>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="column column-4">
                    <div className="overview__text">
                      <div className="overview__pin">
                        <LocationPin className="dark-blue" />
                      </div>
                      <ReactMarkdown source={psi_overview.title} />
                      <div className="large">
                        <ReactMarkdown source={psi_overview.subtitle} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="overview overview--level_practice">
          <div className="overview__background" />
          <div className="overview__inner">
            <div className="overview__flag right" />
            <div className="container">
              <div className="">
                <div className="columns">
                  <div className="column column-4">
                    <div className="overview__text">
                      <div className="overview__pin">
                        <LocationPin className="purple" />
                      </div>
                      <ReactMarkdown source={level_practice_overview.title} />
                      <div className="large">
                        <ReactMarkdown
                          source={level_practice_overview.subtitle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column column-8">
                    <div className="overview-block">
                      <div className="overview-block__chart">
                        <p className="overview-block__chart__text">
                          % of househoulds that spend less than 25% of income on
                          rent
                        </p>
                        {isPreview ? (
                          <div className="chart-placeholder" />
                        ) : (
                          <ResponsiveChart
                            initialWidth={700}
                            initialHeight={420}
                            delay={250}
                            mobileAspectRatio={0.78}
                          >
                            {({ width, height }) => (
                              <LevelPracticeChart
                                width={width}
                                height={height}
                                barWidth={17}
                              />
                            )}
                          </ResponsiveChart>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HomeWrapper>
  )
}

const OutcomesPage = ({
  data: {
    markdownRemark: { frontmatter },
    backgroundImage,
    backgroundImageB,
  },
}) => {
  const fluidImage = get(backgroundImage, "childImageSharp.fluid")
  const fluidImageB = get(backgroundImageB, "childImageSharp.fluid")

  return (
    <Layout title="Outcomes">
      <SEO />
      <OutcomesPageTemplate
        {...frontmatter}
        fluidImage={fluidImage}
        fluidImageB={fluidImageB}
      ></OutcomesPageTemplate>
    </Layout>
  )
}

const ImageBackground = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
`

const HomeWrapper = styled.div`
  .page-header {
    position: relative;
    background: rgba(90, 136, 159, 0.05);
    overflow: hidden;
    padding: 2.5rem 0;
    min-height: 70vh;

    p {
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      font-weight: bold;
      color: ${({ theme }) => theme.grey1};
    }
    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0;
    }

    &__background {
      display: none;
      position: absolute;
      width: 50%;
      height: calc(100% - 5.5rem);
      top: 0;
      left: 50%;
      margin-top: 5.5rem;

      @media (min-width: 1100px) {
        display: block;
      }
    }

    .content-b {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .home-intro {
    padding: 2.8125rem 0 2rem;
    background: #f6f6f6;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      background: white;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      padding: 5rem 0;
      background: white;
    }

    .column {
      margin-bottom: 1.25rem;
    }

    p.large,
    .large p {
      font-size: 1.25rem;
      line-height: 1.6;
      letter-spacing: normal;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        font-size: 1.875rem;
        line-height: 1.33;
        letter-spacing: -0.2px;
      }
    }

    &__text {
      @media (min-width: ${({ theme }) => theme.desktop}) {
        padding-right: 2.5rem;
      }
    }

    &__authors {
      @media (min-width: ${({ theme }) => theme.desktop}) {
        padding-left: 2.5rem;
      }

      &__list {
        display: flex;
        justify-content: flex-start;
        margin-top: 1.25rem;

        @media (min-width: ${({ theme }) => theme.desktop}) {
          justify-content: space-between;
        }

        &__item {
          display: block;
          height: 2.25rem;
          margin-right: 1.25rem;
          text-decoration: none;

          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }

  .overview-block {
    ${({ theme }) => theme.hideBreaks()}
    height: 100%;
    background: purple;
    text-align: center;
    border-radius: 12px;
    padding: 2.5rem 1rem;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      margin-left: -20px;
      margin-right: -20px;
      padding: 1.75rem 20px;
      border-radius: 0;
    }

    &__title {
      margin-bottom: 0.875rem;
    }

    &__subtitle {
      &,
      p {
        font-size: 1.375rem;
      }
    }

    * {
      color: white;
    }

    &__chart {
      width: 100%;
    }

    &__footer {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .chart-wrapper {
    position: relative;
  }
  .overviews {
    padding: 0;
    background: rgba(90, 136, 159, 0.05);
    overflow: hidden;
    z-index: 1;

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 3rem 0;
      padding-top: 0;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      padding: 6.25rem 0;
      padding-top: 0;
    }

    .overview {
      position: relative;
      margin-bottom: 3.5rem;
      z-index: 1;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-bottom: 8.5rem;
      }
      &__text {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }

      &__background {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        @media (min-width: 1129px) {
          display: block;
        }
      }

      &__flag {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: calc(100% - 1.25rem);
        z-index: -1;

        &.left {
          right: auto;
          left: 0;
        }

        &.right {
          right: 0;
          left: auto;
        }

        @media (min-width: ${({ theme }) => theme.tablet}) {
          display: block;
        }
      }

      &:first-child {
        margin-bottom: 6.25rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__text {
        .large p {
          font-size: 1rem;
          line-height: 1.45;
          letter-spacing: normal;
          margin: 0.75rem 0;

          @media (min-width: ${({ theme }) => theme.tablet}) {
            font-size: 1rem;
          }
        }
      }

      &__pin {
        position: relative;
        width: 1.5rem;
        height: 3rem;
        margin-bottom: 1rem;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          margin-bottom: 3.125rem;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 2rem;
          height: 3px;
          left: 50%;
          bottom: 0;
          mix-blend-mode: multiply;
          -webkit-filter: blur(2px);
          filter: blur(2px);
          background: ${({ theme }) => theme.grey1};
          opacity: 0.2;
          transform: translate(-50%, 0);
          border-radius: 1.5px;
        }
      }

      &--site {
        z-index: 2;
      }

      &--outcomes {
        .overview__background {
          height: calc(100% + 22rem);
          background: transparent url("/img/Forme bleu fonce.png") no-repeat
            right center;
          background-size: auto 100%;
        }

        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: ${({ theme }) => theme.greenBlue};
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: ${({ theme }) => theme.greenBlue};
        }

        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 5rem;
          }
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
        }
      }

      &--level_practice {
        .overview__background {
          top: -6.25rem;
          height: calc(100% + 22rem);
          background: transparent url(/img/purple-wave.svg) no-repeat left
            center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-right: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: ${({ theme }) => theme.purple};

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: ${({ theme }) => theme.purple};
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-bottom: 0.625rem;
        }
      }

      &--psi {
        .overview__background {
          top: -6.25rem;
          height: calc(100% + 22rem + 6.25rem);
          background: transparent url("/img/Forme bleu fonce.png") no-repeat
            right center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(85, 122, 159);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(85, 122, 159);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
        }
      }

      &--staff {
        .overview__background {
          z-index: -1;
          top: -7.50rem;
          height: calc(100% + 6.25rem);
          /* height: 100%; */
          background: transparent url("/img/Forme verte.png") no-repeat left
            center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-right: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(130, 182, 138);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(130, 182, 138);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
        }
      }

      &--challenges {
        .overview__background {
          height: calc(100% + 9.75rem);
          background: transparent url("/img/Forme rouge.png") no-repeat right
            center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-left: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(220, 79, 109);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(220, 79, 109);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
        }
      }

      &--future {
        .overview__background {
          top: -9.75rem;
          height: calc(100% + 22rem);
          background: transparent url("/img/Forme verte fonce.png") no-repeat
            left center;
          background-size: auto 100%;
        }
        .overview__text {
          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            padding-right: 5rem;
          }
        }
        .overview-block {
          padding: 4rem 2.5rem 3.125rem;
          background: rgb(78, 133, 104);

          @media (min-width: ${({ theme }) => theme.largeDesktop}) {
            margin-left: 1.75rem;
          }
        }

        .overview__flag {
          background-color: rgb(78, 133, 104);
        }

        .overview-block__chart__text {
          text-align: center;
          font-weight: bold;
          margin-top: 0.625rem;
          margin-bottom: 3rem;
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "outcomes-page" } }) {
      frontmatter {
        page_intro {
          heading
          subtitle
        }
        outcomes_overview {
          title
          subtitle
        }
        staff_overview {
          title
          subtitle
        }
        psi_overview {
          title
          subtitle
        }
        level_practice_overview {
          title
          subtitle
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "methodology-bg-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    backgroundImageB: file(relativePath: { eq: "methodology-bg-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default OutcomesPage
